import { LABEL_VERIFICATION_OPERATORS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Operator User", "has-deactivate-button": false } }, [_c("text-input", { key: "operatorName", attrs: { "label": "Operator Name", "form-item": "", "span": 24, "rules": "required|max:255" } })], 1);
};
var staticRenderFns$1 = [];
var EditOperator_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "EditOperator",
  inject: ["crud", "apiUrl"]
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditOperator = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "label-verification.operators", "api-url": _vm.apiUrl, "redirect-route": "/label-verification/operators", "page": _vm.page } }, [_c("edit-operator")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { EditOperator },
  data() {
    return {
      apiUrl,
      page: LABEL_VERIFICATION_OPERATORS
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
